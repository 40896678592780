import axios from 'axios'
import auth from '@/services/_auth'
import { KMS_BACKEND, appId } from '@/config'
import { handleServiceErrors } from '@/services/errors'

// KMS Backend Registration Service
const registration = {
  // Register management user
  RegisterHOABoardMember: async payload => {
    const url = `${KMS_BACKEND}/User/RegisterHOABoardMember`
    const resp = await axios.post(url, payload).catch(handleServiceErrors)
    return resp.data
  },

  ConfirmEmail: async (userID, confirmEmailToken) => {
    const url = `${KMS_BACKEND}/User/ConfirmEmail`
    const data = { userID, confirmEmailToken, scope: appId() }
    const resp = await axios.post(url, data).catch(handleServiceErrors)

    // Email confirmation gives back valid token
    // Use it to init authenticated user
    const { token, refreshToken, expiration } = resp.data
    auth.saveToken(token, refreshToken, expiration)
    return await auth.initUser()
  },

  GetBoardMemberQuestions: async () => {
    const url = `${KMS_BACKEND}/BoardMemberQuestion/List`
    const resp = await axios.get(url, { headers: auth.authHeaders() }).catch(handleServiceErrors)
    return resp.data
  },

  SubmitBoardMemberAnswers: async payload => {
    const url = `${KMS_BACKEND}/BoardMemberAnswer/SubmitAnswers`
    const resp = await axios
      .post(url, { boardMemberAnswers: payload }, { headers: auth.authHeaders() })
      .catch(handleServiceErrors)
    return resp.data
  },

  // Validates that a signup code exists, and if it does,
  // returns the unit/owner info to display to the user for verification.
  ValidateOwnerSignupCode: async signupCode => {
    const url = `${KMS_BACKEND}/User/ValidateOwnerSignupCode`
    const resp = await axios.get(url, { params: { signupCode } }).catch(handleServiceErrors)
    return resp.data
  },

  // Validates that a signup code exists, and if it does,
  // returns the unit/owner info to display to the user for verification.
  ValidateOwnerSignupCodeWithSuccess: async signupCode => {
    const url = `${KMS_BACKEND}/User/ValidateOwnerSignupCodeWithSuccess`
    const resp = await axios.get(url, { params: { signupCode } }).catch(handleServiceErrors)
    return resp.data
  },

  // Validates that a signup code exists, and if it does,
  // returns true for verification.
  ValidateVendorSignupCodeWithSuccess: async signupCode => {
    const url = `${KMS_BACKEND}/User/ValidateVendorSignupCodeWithSuccess`
    const resp = await axios.get(url, { params: { signupCode } }).catch(handleServiceErrors)
    return resp.data
  },

  // Validates that a signup code exists, and if it does,
  // returns the vendor info to display to the user for verification.
  ValidateVendorSignupCode: async signupCode => {
    const url = `${KMS_BACKEND}/User/ValidateVendorSignupCode`
    const resp = await axios.get(url, { params: { signupCode } }).catch(handleServiceErrors)
    return resp.data
  },

  // Checks whether an identity exists for a given email address in the database.
  // Valid signup code required to prevent possible brute force email address checks
  CheckUserExists: async (emailAddress, signupCode) => {
    const url = `${KMS_BACKEND}/User/CheckUserExists`
    const resp = await axios
      .get(url, { params: { emailAddress, signupCode } })
      .catch(handleServiceErrors)
    return resp.data
  },

  // Register homeowner
  RegisterOwner: async payload => {
    const url = `${KMS_BACKEND}/User/RegisterOwner`
    const resp = await axios.post(url, payload).catch(handleServiceErrors)
    return resp.data
  },

  // Register vendor
  RegisterVendor: async payload => {
    const url = `${KMS_BACKEND}/User/RegisterVendor`
    const resp = await axios.post(url, payload).catch(handleServiceErrors)
    return resp.data
  }
}

export default registration
