<template>
  <div class="section confirm-email">
    <div class="container">
      <div class="columns level">
        <div class="column has-text-centered">
          <img src="@/assets/coming_soon.svg" class="coming-soon-img" />
        </div>
        <div class="column is-three-fifths">
          <div class="form-box">
            <transition name="fade" mode="out-in" appear>
              <div v-if="error">
                <h3>Sorry about this.</h3>
                <h4>Unfortunately, an error has occured.</h4>
                <p v-if="error">{{ error }}</p>
              </div>
              <div v-else-if="complete">
                <h3>Welcome Back!</h3>
                <h4>You email has been confirmed.</h4>
                <!-- <router-link to="/completeProfile" class="button is-primary is-medium">
                  Complete your profile
                </router-link> -->
              </div>
              <div v-else>
                <h3>Welcome Back!</h3>
                <h4>Confirming your email...</h4>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import registration from '@/services/registration'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      error: '',
      complete: false
    }
  },

  metaInfo: {
    title: 'Confirm email'
  },

  computed: {
    debug() {
      const params = this.$route.query

      return JSON.stringify({
        params
      })
    }
  },

  async mounted() {
    const { u, t } = this.$route.query
    this.error = ''
    this.complete = false
    if (u && t) {
      try {
        await registration.ConfirmEmail(u, t)
        this.complete = true

        // re-initialize auth. We should be logged in now.
        this.initAuth()
      } catch (e) {
        if (e.message === 'Invalid token.') {
          this.error = 'This link is not valid. The token may have expired.'
        } else {
          this.error = e.message
        }
      }
    }
  },

  methods: {
    ...mapActions('user', ['initAuth'])
  }
}
</script>
